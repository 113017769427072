
import './App.css';
import {useState, useEffect} from 'react';
import Amplitude from 'amplitudejs'

function App() {
  // const [message, setMessage] = useState("");

  // useEffect(() => {
  //   fetch("http://localhost:5000")
  //     .then((res) => res.json())
  //     .then((data) => setMessage(data.message));
  // }, []);



  useEffect(()=>{
    Amplitude.init({
    "bindings": {
      32: 'play_pause'
    },
    "songs": [
      {


        "url": "/music/mus1.mp3",

      } 
    ]
  });
  
  window.onkeydown = function(e) {
      return !(e.keyCode == 32);
  };  
  })

  /*
    Handles a click on the song played progress bar.
  */

  // Amplitude.init({
  //   "bindings": {
  //     32: 'play_pause'
  //   },
  //   "songs": [
  //     {
  //       "url": "music/mus1.mp3",
  //     }
  //   ]
  // });

  // window.onkeydown = function(e) {
  //     return !(e.keyCode === 32);
  // };

  return (

    <section>
      {/* <div id="single-song-player">
        <div className="control-container">
          <div className="amplitude-play-pause" id="play-pause">
         </div>
        </div>
      </div> */}
  <div id="single-song-player">
        <div class="control-container">
          <div class="amplitude-play-pause" id="play-pause"></div>
        </div>
      </div>
<div className="title">
 <h1>BLANC DANI</h1>
</div>

<div className='footer'>
  <a className='rocalink' href='https://roca.blancdani.com'>Roca Shop</a>
</div>
</section>


  );
}
export default App;
